.stickers {
  display: flex;
  // height: 100vh;
  // background-color: $page-bg;

  &__thead {
    display: flex;
    font-size: 1.7rem;
    text-align: center;
    background-color: $page-bg;
    padding: 1.412em 0;
    padding-left: 8.235em;
    margin-top: 2.647em;

    li {
      font-weight: 500;

      &:nth-child(1) {
        width: 12.5em;
        text-align-last: left;
      }

      &:nth-child(2) {
        width: 17.529em;
      }

      &:nth-child(3) {
        width: 7.4705em;
      }

      &:nth-child(4) {
        width: 14em;
      }
    }
  }

  &__table-wrapper {
    // max-height: 20em;
    font-size: 1.7rem;
    // overflow-y: auto;

    // &::-webkit-scrollbar {
    //   width: 0.294em;
    //   background-color: transparent; 
    // }
    // &::-webkit-scrollbar-track {
    //   margin-bottom: 1.176em;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: $active;
    //   border-radius: 0.588em;
    // }
  }

  &__table {
    width: 100%;
    text-align: center;

    td:nth-child(2) {
      text-align: left;
    }

    tr:nth-child(1) {
      td {
        padding-top: 1.6em;
      }
    }

    td {
      font-size: 1.7rem;
      font-weight: 400;
      padding: 0.4706em 0;

      &:nth-child(1) {
        width: 8.1em;
      }

      &:nth-child(2) {
        width: 12.5em;
      }

      &:nth-child(2),
      &:nth-child(3) {
        font-weight: 700;
      }

      &:nth-child(5) {
        width: 14em;
      }

      &:nth-child(7) {
        width: 7.5em;
      }
    }
  }

  &__img {
    border-radius: 50%;

    img {
      width: 3em;
    }
  }

  &__status {
    &_green {
      color: #73B709;
    }

    &_red {
      color: #E22432;
    }

    &_orange {
      color: #F3B738;
    }
  }

  &__edit {
    display: inline-block;
    width: 1.4em;

    &:hover,
    &:focus {
      filter: brightness(50%);
    }

    img {
      width: 100%
    }
  }

  &__button-icon {
    width: 2.7em;
    font-size: 1.7rem;
    background-color: transparent;
    padding: 0.5em;
    border: none;
    cursor: pointer;

    &_opacity {
      opacity: 0.5;
    }

    &:hover,
    &:focus {
      filter: brightness(50%);
    }

    img {
      width: 100%;
    }
  }

  &__list {
    &--table {
      display: table;
      margin-top: 50px;

      &-header {
        display: table-header-group;

        &>div {
          background: #F7F7F7;
          font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
        }

        .stickers__list--table-cell {
          height: 70px;
        }
      }

      &-body {
        display: table-row-group;

        .stickers__list--table-row {
          &:first-child {
            .stickers__list--table-cell {
              padding-top: 65px;
            }
          }

          &:last-child {
            .stickers__list--table-cell {
              padding-bottom: 65px;
            }
          }

          .stickers__list--table-cell {
            padding-top: 30px;
            padding-bottom: 30px;

            &:first-child {
              padding-left: 65px;
            }

            &:last-child {
              padding-right: 65px;
            }
          }
        }
      }

      &-row {
        display: table-row;
      }

      &-cell {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #000000;

        &-logo {
          width: 70px;
          height: 70px;
          border-radius: 100%;
        }

        &-name {
          font-weight: 700;

          color: #000000;
        }

        &-status {
          font-weight: 700;
          text-align: center;

          &_green {
            color: #73B709;
          }

          &_orange {
            color: #F3B738;
          }

          &_red {
            color: #E22432;
          }
        }
      }
    }
  }
}