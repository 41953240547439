.socials-settings-table-body-row {
  &__input {
    & .MuiInputBase-input {
      padding: 16px 22px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #5A5A5A;
    }

    & .MuiFormHelperText-root {
      position: absolute;
      bottom: -20px;
    }
  }

  &__actions {
    &--delete {
      cursor: pointer;

      path {
        transition: fill .2s ease-in-out;
      }

      &:hover {
        path {
          fill: #ae1b2c;
        }
      }

      &:hover {
        path {
          fill: #ae1b2c;
        }
      }

      &:active {
        path {
          fill: #861422;
        }
      }
    }
  }

  &__cell {
    box-sizing: border-box;

    &-left {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    &-right {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }

    &-container {
      padding-left: 22px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #5A5A5A;
    }
  }
}