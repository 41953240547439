.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}

.search {
  &__wrapper {
    padding: 15px 0 45px 30px;
    box-sizing: border-box;
  }
}

.arbutton {
  &__add {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: inherit;
  }
}