.main_layout {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  overflow-y: scroll;
  background: #F9F9F9;

  &__main {
    flex: 1;
  }

  &__footer {
    box-sizing: border-box;
    padding: 24px 0 12px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

  }

  &__right_bar {}
}