.arl-preview {
  position: relative;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.5em;
    height: 5.5em;
    font-size: inherit;
    border: none;
    border-radius: 1.2em;
    cursor: pointer;

    &_circle {
      border-radius: 50%;
      width: 3.65em;
      height: 3.65em;
    }

    &_trayicon {
      width: 80px;
      height: 55px;
      border-radius: 53px;
      margin-top: 30px;
    }

    &_rectangle {
      width: 12.85em;
      height: 4.15em;
      border-radius: 0.5em
    }

    &_middle {
      width: 15.2em;
      height: 9.1em;
      border-radius: 0.5em;
    }

    &_big {
      width: 15.2em;
      height: 10.2em;
      border-radius: 0.5em;
    }
  }

  &_hidden {
    display: none;
  }

  &_close {
    transform: rotate(45deg);
    position: absolute;
    right: 0;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: min-content;
    width: min-content;
  }
}