.app-input-picture {
  box-sizing: border-box;
  width: max-content;

  &__img {
    position: relative;
  }

  &__delete-img {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    cursor: pointer;
  }

  &__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
  }

  &_circle {
    border-radius: 100%;
  }

  &__label {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #595959;
    user-select: none;

    &_margin-top {
      margin-bottom: 14px;
    }

    &_margin-right {
      margin-left: 14px;
    }

    &_margin-bottom {
      margin-top: 14px;
    }

    &_margin-left {
      margin-right: 14px;
    }
  }

  &_label-position {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    &_top {
      flex-direction: column-reverse;
    }

    &_right {
      flex-direction: row;
    }

    &_bottom {
      flex-direction: column;
    }

    &_left {
      flex-direction: row-reverse;
    }
  }
}