.stickers-table {
  td {
    padding: 1.5rem 0;
    
    &:first-child {
      padding-left: 4rem;
    }

    &:nth-child(1) {
      width: 2.5rem;
    }

    &:nth-child(2) {
      min-width: 10rem;
    }

    &:nth-child(3) {
      width: 55rem;
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      width: 14rem;
      text-align: center;
    }
    &:nth-child(7) {
      width: 3rem;
    }
    
    &:last-child {
      padding-right: 4rem;
    }
  }

  &__thead {
    min-width: 70rem;
    width: 100%;
    background-color: $page-bg;
    padding: 1.5rem 4rem 1rem;

    td {

      &:nth-child(3) {
        white-space: nowrap;
        span {
          font-weight: 500;
          margin-right: 1.7rem;
        }
      }

      &:nth-child(7) {
        width: 7rem;
      }

    }
  }

  &__tbody {
    min-width: 70rem;
    width: 100%;

    td {
      &:nth-child(2) {
        display: flex;
        justify-content: center;
      
        img {
          max-width: 5rem;
        }
      }
    }
  }

  &__search {
    width: 55%;
    max-width: 20rem;
    font-size: 1em;
  }

  &__wrapper {
    padding: 0 4rem;
  }
}