.finance {
  display: flex;
  background-color: $page-bg;

  &__total-table {
    @media screen and (max-width: 1000px) {
      font-size: 1.9rem;
    }

    @media screen and (max-width: 1000px) {
      font-size: 1.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__finance-table {
    font-size: 1.7rem;
    margin: 4rem 5rem 4rem;

  }
  
  &__stickers-table {
    font-size: 1.7rem;
  }
}