.scroll {
  overflow-y: auto;
  overflow-x: clip;

  &::-webkit-scrollbar {
    width: 0.294em;
    height: 0.294em;
    background-color: transparent; 
  }

  &::-webkit-scrollbar-track {
    margin-bottom: 1.176em;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: $active;
    border-radius: 0.588em;
  }

  &_x {
    overflow-y: clip;
    overflow-x: auto;
    
    &::-webkit-scrollbar-track {
      margin: 0 1.176em;
    }
  }

  &_margin-none {
    &::-webkit-scrollbar-track {
      margin: 0;
    }
  }
}