.date-picker-btn {
  &__btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 18px;
    border-radius: 0px 35px 0px 18px;
    transition: background 0.1s ease-in-out;
    cursor: pointer;

    &:active {
      background: #a31a2b !important;
      box-shadow: inset 0px 0px 5px #828282;
    }

    &--title {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      margin-left: 10px;
      user-select: none;
    }
  }

  &__picker {
    &>div {
      &:first-child {
        color: red !important;
        display: none;
      }
    }
  }

  .MuiButton-root {
    color: #ffffff !important;
    padding: 16px !important;
    border-radius: 0px 35px 0px 18px !important;
  }
}