.footer {
  font-size: 1.4rem;
  padding: 3.429em 0 1.5rem;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  &__text {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    letter-spacing: 0.116667rem;
    font-size: 1em;
    font-weight: 700;
    color: #7B7B7B;
  }
}