.sticker {
  &__wrapper {}

  &__content {}

  &__item {
    flex-basis: 16.666%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    width : 180px;
    height  : 200px;

    &:last-child {
      margin-right: auto;
    }
  }

  &__card {
    font-size: 1em;
  }
}