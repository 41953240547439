.al-text-field {
  width: 100%;

  &__input {
    width: 100%;
    font-size: inherit;
    background-color: #EDEDED;
    padding: 16px;
    border: none;
    border-radius: 12px;
    color: #4D4D4D;
    overflow: hidden;
    box-shadow: 0 0 0 0.15em transparent;

    &:disabled {
      background: #D8D8D8;
      color: #FFFFFF;

      &:hover,
      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: #FFFFFF;
        text-transform: uppercase;
        min-height: 100%;
      }
    }

    &_big {
      height: 100%;
    }

    &_caps {
      text-transform: uppercase;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0.15em #CF2136;
    }

    &::placeholder {
      color: #4D4D4D;
      text-transform: uppercase;
      min-height: 100%;
    }
  }
}