.socials-settings-table-header {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  &__btn {
    &-add {
      margin-left: 24px !important;
    }
  }
}