.search {
  position: relative;
  display: inline-block;
  // font-size: 2.4rem;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0.667em;
    width: 0.833em;
    height: 0.833em;
    background-image: url('../../img/search.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 2;
    transform: translateY(-50%);
  }

  &__input {
    height: 1.958em;
    width: 100%;
    min-width: 8em;
    font-size: inherit;
    color: $text-color;
    background-color: $component-bg;
    padding-left: 2em;
    border: 0.041em solid $active;
    border-radius: 3em;

    &::placeholder {
      min-height: 100%;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0.07em $active;
    }
  }

}