.create {
  display: flex;
  background-color: $page-bg;

  &__warning {
    position: relative;
    color: $active;
    text-transform: uppercase;
    font-size: 0.8em;
    padding-left: 1rem;
    margin-left: 4rem;

    &::before {
      content: '';
      position: absolute;
      top: 35%;
      // left:rem;
      right: 100%;
      display: inline-block;
      width: 4rem;
      height: 4rem;
      background-image: url('../../img/warning.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
    }
  }

  &__nav {
    font-size: 2.4rem;
    background-color: $page-bg;
    margin-top: 4.5rem;
  }

  &__nav-list {
    display: flex;
    padding-left: 4.7rem;
  }

  &__nav-button {
    position: relative;
    font-size: 1.7rem;
    font-weight: 500;
    background-color: transparent;
    padding: 2.7rem 2.5rem 1.8rem 2.5rem;
    border-bottom: 0.3rem solid transparent;
    
    border: none;
    margin-right: 4.5rem;
    cursor: pointer;
    
    &:hover {
      color: #FFFFFF;
      background-color: $active;
      border-bottom: 0.3rem solid $active;

      &::before {
        display: none;
      }
    }
    
    &_active {
      border-bottom: 0.3rem solid $active;
    }

    &_warning {
      &::before {
        content: '';
        position: absolute;
        top: 2.7rem;
        left: 0.4rem;
        display: inline-block;
        width: 1.7rem;
        height: 1.7rem;
        background-image: url('../../img/warning.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}