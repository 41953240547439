.app-dialog {
  left: 128px;
  z-index: 2;
  width: calc(100% - 128px);

  &,
  &__backdrop,
  &__main {
    position: absolute;
    height: 100vh;
  }

  &__backdrop,
  &__main {
    width: 100%;
  }

  &__backdrop {
    background: #000000;
    mix-blend-mode: normal;
    opacity: 0.43;
    color: red;
  }

  &__main {
    color: black;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    &_center {
      align-items: center;
    }

    &_bottom {
      align-items: flex-end;
    }
  }
}