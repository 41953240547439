.languages {
  .language-form {
    &__card {
      width: 100%;
      min-width: 768px;
      max-width: 1280px;
      margin: 0 88px 0 48px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__container {
      box-sizing: border-box;
      padding: 20px 24px;
    }

    &__header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
    }

    &__save {
      margin-left: 24px;
    }

    &__main {
      margin-top: 14px;
    }

    &__table {
      min-height: 480px;
    }
  }
}