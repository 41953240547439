*,
::after,
::before {
  font-family: 'HelveticaNeue';
  font-weight: 300;
  color: $text-color;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: 0;
}

html {
  font-size: 0.625em;
}

body {
  font-size: 2.4rem;
}

ul {
  list-style: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
	display: none
}

table {
   border-collapse: collapse;
}