.main-layout
{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  overflow: hidden;
  height: 100vh;
}

.main-content__wrapper
  {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    height: 100%;
    margin-top: 95px;
  }