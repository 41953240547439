.stickers {
  height: 100%;
  font-size: 2.4rem;
  padding: 2rem;
  // background-color: #FFFFFF !important;

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    flex-basis: 16.666%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    border-radius: 1em;

    &:last-child {
      //margin-right: auto;
    }
  }

  &__card {
    font-size: 1em;
  }

  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.3em;
    height: 9.8em;
    padding: 0.5em 0;
    border-radius: 1em;
    background-color: $page-bg;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: darken($page-bg, 10%);
    }

    img {
      width: 20%;
    }
  }
}