.app-select {
  & label.Mui-focused {
    color: #CF2C37 !important;
    font-size: 16px;
  }

  & label {
    font-size: 14px;
  }

  & .MuiFormHelperText-root {
    font-size: 14px;
  }

  & .MuiOutlinedInput-root {
    // border-radius: 23px;
    background: white;
    font-size: 16px;

    & fieldset {
      // border-color: white;

      legend {
        font-size: 12px;
      }
    }

    &:hover fieldset {
      border-color: white,
    }

    &.Mui-focused fieldset {
      border-color: #CF2C37;
    }
  }

  & .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  &__item {
    font-size: 14px !important;
  }
}