.add-img-stub {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  //flex-basis: 16.666%;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #EDEDED;

  &_disabled {
    opacity: 0.6;
    cursor: default;
  }
  &:not(.add-img-stub_disabled):hover {
    background-color: darken(#d4d4d4, 10%);
  }
  &__stickers {
    width: 7.3em;
    height: 9.8em;
    font-size: 2.4rem;
    border-radius: 1em;
    margin-bottom: 2rem;
    border-radius: 1em;
  }
  &__previews {}
}

// add-img-stub__stickers