.artable {
  box-sizing: border-box;

  &__container {
    width: 100%;
    padding: 24px;
  }

  &-main {
    &--table {
      &__head {
        & .MuiTableCell-head {
          font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          color: #000000;
          background: #F5F5F5;

          &:first-child {
            border-top-left-radius: 18px;
            border-bottom-left-radius: 18px;
          }

          &:last-child {
            border-top-right-radius: 18px;
            border-bottom-right-radius: 18px;
          }
        }
      }

      &__body {
        & .MuiTableCell-body {
          font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          color: #000000;
        }
      }
    }

    &__stubs {
      margin-top: 32px;
    }

    &__stub {
      margin-top: 12px;
    }
  }
}