.additional-links {
  width: 88%;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item {
    &-wrapper {
      flex-basis: 48%;
    }
    &-content {
      display: flex;
      align-items: center;
      margin-top: 1em;
    }
  }
  &__input {
    width: 100%;
    margin-left: 1em;
  }
  &__button {
    background: #FFFFFF;
    transition: transform 0.2s ease;

    &:hover,
    &:focus {
      background-color: #FFFFFF !important;
    }

    &_active {
      transform: rotate(45deg);
      z-index: 3;
    }
  }
}