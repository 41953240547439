.artable-empty-case {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 120px;

  &__action-btn {
    width: max-content;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 24px !important;
    text-transform: none !important;
  }
}