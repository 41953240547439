.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  height: 48px;
  width: 280px;
  padding: 0 15px;
  margin: 2px;
  font-size: inherit;
  color: #4D4D4D;
  background-color: #FFFFFF;
  border: 1px solid #CF2136;
  border-radius: 100px;
  transition: box-shadow .2s ease;

  &:hover {
    box-shadow: 0 0 0 0.07em #cf2136;
  }
}

.label {
  margin-right: 15px;
  font-weight: inherit;
}

.search {
  height: 48px;
  flex: 1;
}