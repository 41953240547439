.settings-data {
  width: 100%;

  &__item {
    margin-top: 36px;

    &:first-child {
      margin-top: 0;
    }
  }
}