.container {
  button {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #2B2B2C;
    border-radius: 35px 0px 0px 35px;
    background: #F9F9F9;
    transition: background .25s ease-in-out;
  }

  button[id="full-width-tab-1"] {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  button[aria-selected="true"] {
    background: #CF2136;
    color: #FFFFFF;
  }
}