.analitics-table {
  width: 100%;

  &-wrapper {
    min-width: 65rem;
    max-height: 40rem;
  }

  td {
    padding: 1.5rem 0;

    &:first-child {
      width: 1rem;
      padding-left: 4rem;
    }

    &:nth-child(2) {
      width: 10rem;
      text-align: center;
    }


    &:nth-child(3) {
      min-width: 24rem;
      font-weight: 500;

      span {
        margin-right: 1.5rem;
        font-weight: 500;
      }
    }

    &:nth-child(4),
    &:nth-child(5) {
      width: 20rem;
      text-align: center;
    }
    
    &:last-child {
      padding-right: 4rem;
    }

  }

  &__img {
    max-width: 5rem;
  }
}