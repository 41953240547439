.wrapper {
  color: red;
}

.common {
  width: 100%;
  height: 100%;
  font-size: inherit;
  color: #4D4D4D;
  background: transparent;
  border: none;
  padding: 15px;
}

.empty {
  height: 1.958em;
  width: 100%;
  min-width: 8em;
  font-size: inherit;
  color: #4D4D4D;
  background-color: #FFFFFF;
  padding-left: 2em;
  border: 0.041em solid #CF2136;
  border-radius: 3em;
}