.the_copyright {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1.16667px;
  color: #5C5B5B;
  margin: 24px 0 12px 0;
  text-align: center;
}