.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5em;
  height: 5.5em;
  font-size: inherit;
  background-color: $item-bg;
  border: none;
  border-radius: 1.2em;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: darken($item-bg, 10%);
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;

    &:hover,
    &:focus {
      background-color: $item-bg;
    }
  }

  &_small {
    width: 1.8em;
    height: 1.8em;
    background-color: transparent;
  }
  
  &_circle {
    border-radius: 50%;
    width: 3.65em;
    height: 3.65em;
  }

  &_rectangle {
    width: 12.85em;
    height: 4.15em;
    border-radius: 0.5em;
  }
  
  &_middle {
    width: 15.2em;
    height: 9.1em;
    border-radius: 0.5em;
  }
  
  &_big {
    width: 15.2em;
    height: 10.2em;
    border-radius: 0.5em;
  }

  img {
    width: 1.8em;
  }
}