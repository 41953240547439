.sidebar {
  box-sizing: border-box;
  padding: 30px 20px;
  height: 100vh;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  &__top,
  &__bottom,
  &__menu_items {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

  &__logo {
    margin-bottom: 40px;
  }

  &__item {
    display: block;
    margin-top: 40px;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }
  }
}