.arbutton {
  font-family: 'Helvetica Neue';
  border-radius: 23px !important;
  width: 217px;
  height: 61px;

  &_contained {
    // background: #CF2C37 !important;
  }

  &_outlined {
    color: #CF2C37 !important;
    border: 1px solid #CF2C37 !important;

    &:hover {
      background: rgba(207, 44, 55, 0.05) !important;
    }
  }

  &__title {
    font-family: 'Helvetica Neue';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-right: 24px;
  }

  &__loader {
    &_white {
      circle.MuiCircularProgress-circle {
        color: #FFFFFF;
      }
    }
  }
}