.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50em;
  min-height: 10em;
  max-height: none;
  font-size: 2.4rem;
  background-color: $component-bg;
  border-radius: 0.972em;
  box-shadow: 0 0.375em 1.504em rgba(0, 0, 0, 0.08);
  
  &_height {
    max-height: none;
  }
  
  &-wrapper {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    // width: 1148px;

    //min-height: 100vh;
    //padding: 12rem 1.5rem 0;
    //margin-left: 25.8rem;
    // padding: 15px 20px;

    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }
    
    @media screen and (max-width: 600px) {
      padding: 9rem 1.5rem 0;
    }
  }

  &-logo {
    position: fixed;
    left: 0;
    top: 0;
    height: 95px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $page-bg;
    z-index: 5;

    @media screen and (max-width: 1200px) {
      left: 0;
      padding-right: 0;
    }

    @media screen and (max-width: 600px) {
      font-size: 1.2em;
      justify-content: flex-end;
      padding: 1em 1.5em;
    }

    img {
      width: 7.667em;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 2em;
  }

  &__search {
    font-size: 0.708em;
  }

  &__button {
    margin-left: auto;
  }
}