.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}

.title {
  flex: 20%;
  text-align: right;
}
.chart {
  flex: 70%;
  margin: 0 15px;
}
.text {
  flex: 10%;
}