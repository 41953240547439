.container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 40rem;
  font-weight: 500;
  background-color: #FFFFFF;
  padding: 1em 1.458em;
  border-radius: 0.972em;
  box-shadow: 0 0.375em 1.504em rgba(0, 0, 0, 0.08);
}

.platform {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #2B2B2B;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;

  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
  }

  &__android {
    bottom: 60px;
    left: 30px;

    &::before {
      background-color: #82EE95;
    }
  }

  &__ios {
    top: 70px;
    right: 70px;

    &::before {
      background-color: #000000;
    }
  }

  &__count {
    font-weight: 400;
    position: absolute;
    left: 167px;
    top: 165px;

    &--title,
    &--subtitle {
      color: inherit;
    }

    &--title {
      font-size: 24px;
      line-height: 24px;
    }

    &--subtitle {
      font-size: 14px;
      line-height: 14px;
    }

    &,
    &--android,
    &--ios {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    &--android {
      margin-top: 20px;
      color: #82EE95;
    }

    &--ios {
      color: #2B2B2B;
    }
  }
}

.chart {
  margin: 20px 30px 40px;
}