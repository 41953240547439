.signin {
  width: 100% !important;

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: 100%;
  }

  &__login {
    flex: 50%;
    position: relative;

    &_backdrop {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-top-left-radius: 41px;
      border-bottom-left-radius: 41px;
    }

    &_content {
      position: absolute;
      box-sizing: border-box;
      padding: 48px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
    }

    &_logo,
    &_form {
      width: 100%;
    }

    &_form {
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;

      &__field {
        &_password {
          margin-top: 32px !important;
        }
      }

      &__actions {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-end;
        margin-top: 18px;
      }

      &__action {
        &_forgot {
          font-family: 'Helvetica Neue';
          font-weight: 400;
          font-size: 21px;
          color: #FFFFFF;
          user-select: none;
          cursor: pointer;
          transition: color .1s ease;

          &:hover {
            color: #CF2C37;
          }

          &:active {
            color: #a51822;
          }
        }

        &_btn {
          margin-top: 28px !important;
        }

        &_signin {
          background: #CF2C37 !important;
          border-radius: 23px !important;
          width: 217px;
          height: 61px;
          margin-top: 32px !important;

          &__title {
            font-family: 'Helvetica Neue';
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-transform: capitalize;
            color: #FFFFFF;
            margin-right: 24px;
          }
        }
      }
    }
  }

  &__info {
    flex: 50%;
    box-sizing: border-box;
    padding: 48px 32px;
    user-select: none;

    &_title {
      box-sizing: border-box;
      padding-left: 16px;

      font-family: "HelveticaNeueCondensed";
      font-weight: 700;
      font-size: 60px;
      line-height: 73px;
      color: #000000;
      text-transform: uppercase;
      width: 80%;

      &__chunk {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        text-transform: inherit;
      }
    }

    &_list {
      margin-top: 32px;

      &__item {
        box-sizing: border-box;
        margin-left: 30px;
        position: relative;
        font-weight: 300;
        font-size: 24px;
        line-height: 29px;
        color: #000000;

        &::before {
          position: absolute;
          content: "";
          left: -30px;
          top: 4px;
          width: 15px;
          height: 15px;
          border-radius: 100%;

          background: #CF2C37;
        }
      }
    }

    &__feedback_email {
      user-select: text;
      font-weight: bold;
    }
  }
}