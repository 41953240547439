@keyframes pull {
  from {
    transform: translateY(100%);
  }
  
  to {
    transform: translateY(0);
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}