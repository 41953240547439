.download-info {
  // display: flex;
  // justify-content: flex-end;
  // align-items: center;

  td:nth-child(2) {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  
  &__title {
    text-align: right;
    font-size: 0.75em;
    font-weight: 500;
  }

  &__progress-bar {
    position: relative;
    // width: 27rem;
    width: 100%;
    height: 0.5em;
    background-color: $page-bg;
    border-radius: 2rem;
    // padding: 0 1rem;

    span {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 50%;
      height: 100%;
      background-color: $active;
      border-radius: inherit;
      transition: width .4s ease-in-out;
    }
  }

  &__procents {
    width: 5rem;
    font-size: 0.75em;
    font-weight: 500;
    text-align: right;
  }
}