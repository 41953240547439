.analitics-thead {
  background-color: $page-bg;
  min-width: 70rem;

  td {
    padding: 1.5rem 0 1rem;

    &:first-child {
      width: 1rem;
      padding-left: 4rem;
    }

    &:nth-child(2) {
      width: 10rem;
      min-width: 7rem;
    }

    &:nth-child(3) {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 63rem;

      span {
        font-size: 1.1em;
        font-weight: 500;
        margin-right: 1.5rem;
      }
    }

    &:nth-child(4) {
      width: 20rem;
      text-align: center;
    }

    &:nth-child(5) {
      width: 20.5rem;
      text-align: center;
      padding-right: 4.5rem;
    } 
  }

  &__search {
    font-size: 1em;
  }
}