.container {
  margin-left: 48px;
  width: 100%;
  margin-left: 48px;
  background-color: #FFFFFF;
  padding: 1em 1.458em;
  border-radius: 0.972em;
  box-shadow: 0 0.375em 1.504em rgba(0, 0, 0, 0.08);
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  color: #2B2B2B;
}

.header {
  font-size: 24px;
  line-height: 24px;
}

.title {}

.tabs {}

.header,
.body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.body {
  align-items: flex-start;
}

.earth {
  width: 300px;
  height: 200px;
}

.countries {
  flex: 1;
}