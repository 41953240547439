.finance-table {
  display: flex;
  flex-direction: column;
  // overflow-x: auto;

  tr {
    border-bottom: 0.1rem solid #7B7B7B;
  }

  td {
    font-weight: 500;
    padding-bottom: 1rem;

    &:nth-child(1) {
      width: 11rem;
    }

    &:nth-child(2) {
      width: 21.5rem;
      text-align: center;
    }

    &:nth-child(3) {
      width: 26rem;
      text-align: center;
    }

    &:nth-child(4) {
      width: 17rem;
    }

    &:nth-child(5) {
      width: 34rem;
    }
  }

  &__thead {
    min-width: 68rem;
  }

  &__wrapper {
    min-height: 10rem;
    max-height: 25rem;
    min-width: 68rem;
  }
  
  &__table {
    width: 100%;
    
    td {
      padding: 2rem 0;
    }
  }
}