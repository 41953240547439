.information {
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;

  &__title {
    font-size: 1.01em;
    font-weight: 500;
  }

  &__title_margin {
    margin-bottom: 1em;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    &--name,
    &--code {
      width: 420px !important;
    }

    &--copyright {
      margin-top: 100px;

      &-input {
        width: 420px !important;
      }
    }

    &--dictionary {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__row_margin {
    margin-bottom: 1em;
  }

  &__id-block {
    flex-basis: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 9.118em;
    margin-bottom: 1.7em;

    &--uuid-pack {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #5A5A5A;
      height: 48px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: center;
      align-items: flex-start;
    }

    &--price-input {
      // margin-top: 40px;
    }
  }

  &__id-block-radio {
    display: flex;
    justify-content: space-between;
    padding-left: 1.176em;
    padding-right: 3.529em;
    margin: 2rem 0 1.3rem;
  }

  &__info-block {
    flex-basis: 64%;
    display: flex;
    flex-direction: column;
    min-height: 9.118em;
    margin-bottom: 1.7em;
    margin-left: 40px;
  }

  &__info-block-select {
    font-size: 1.1em;
    background-color: transparent;
  }

  &__info-block-row {
    display: flex;
    align-items: center;
    margin-bottom: 1.76em;

    // input {
    //   flex-basis: 21.176em;
    //   margin-right: 1.176em;
    // }

    select {
      display: inline-block;
    }
  }

  &__add-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__add-block-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.8em;
  }

  &__add-block-item_banner {
    padding-top: 0;

    label {
      height: 0;
      padding-bottom: 1.8em;
      margin-right: auto;
    }

    input {
      margin-right: 1em;
    }
  }

  &__add-block-size {
    font-size: 0.6em;
    margin-top: 0.7em;
  }

  &__add-link {
    display: flex;
    align-items: center;
    flex-basis: 48%;
    // font-weight: 500;

    input {
      width: 100%;
      margin-left: 0.8em;
    }
  }

  &__select {
    &-label {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin-top: 1em;
      margin-right: 2em;
    }

    &-title {
      margin-right: 10px;
    }
  }
}