.add-stickers {
  padding: 40px;

  &__toggle {
    margin-bottom: 3.2em;
  }

  &__text {
    font-size: 0.97em;
    font-weight: 500;
    margin-bottom: 3.2em;
  }

  &__drop-area {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  &__button {
    font-size: 1em;
    font-weight: 700;
    color: $active;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
      color: lighten($active, 20%)
    }
  }

  &__stickers-tab {
    width: 100%;
    height: 100%;
  }

  &--new-pack {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.detail-pack--preloader { // FIXME
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 300px;
}