.login {
  display: flex;
  min-height: 100vh;
  background-color: $page-bg;
  padding-top: 5rem;
  
  
  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    height: inherit;
    flex-direction: column;
    padding: 0 1.5rem;
  }

  &__logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 2rem;
    margin-bottom: 1.5em;

    img {
      &:first-child {
        max-width: 4.75em;
        margin-bottom: 1em;
      }

      &:last-child {
        max-width: 7.389em;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 27.556em;
    font-size: 2rem;
    background-color: $component-bg;
    padding: 2.25em 0 0 2.25em;
    border-radius: 0.972em;
    overflow: hidden;
    box-shadow: 0 0.375em 1.504em rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 500px) {
      padding: 2.25em 0 0 1.5em;
    }
  }

  &__input {
    font-size: 1em;
    line-height: 1em;
    background-color: $page-bg;
    padding: 0.556em;
    border-radius: 3em 0 0 3em;

    &::placeholder {
      text-transform: none;
    }

    &:first-child {
      margin-bottom: 1.389em;
    }

    &:nth-child(2) {
      margin-bottom: 0.5em;
    }
  }

  &__forget-pass {
    align-self: flex-end;
    font-size: 0.75em;
    color: $active;
    text-decoration: none;
    margin-bottom: 2.7em;
    margin-right: 2rem;
    
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__submit {
    max-width: 9.289em;
    height: 2.472em;
    font-size: 1em;
    font-weight: 300;
    align-self: flex-end;
    text-transform: none;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
    width: 100%;
    max-width: 136rem;
    padding: 1.5rem 2rem;

    @media screen and (max-width: 1000px) {
      flex-direction: column-reverse;
      padding: 3rem;
    }
  }

  &__text {
    width: 70%;
    text-align: center;
    padding-left: 30%;

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding-left: 0;
    }
  }

  &__links {
    display: flex;
    justify-content: flex-end;
    width: 30%;

    @media screen and (max-width: 1000px) {
      justify-content: center;
      width: 100%;
      margin-bottom: 2rem;
    }

    a {
      text-decoration: none;
      line-height: 0.7;
      margin-left: 1.425em;

      svg {
        width: auto;
        height: 100%;
        max-height: 2.7rem;
        &:hover,
        &:focus {
          fill: darken(#7B7B7B, 10%)
        }
      }
    }
  }
}