.drop-area {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  border: 3px dashed black;
}