.total-table {
  @media screen and (max-width: 600px) {
    font-size: 0.8em;
  }

  tr {
    font-size: inherit;
  }

  td {
    font-size: inherit;
    text-align: left;
    width: 7em;
    padding: 0.625em 0;
    white-space: nowrap;

    &:last-child {
      width: auto;
      min-width: 5em;
    }
  }

  &__thead {
    font-size: inherit;

    td {
      padding-top: 0.417em;

      &:first-child {
        padding-left: 1.25em;
      }
      
      &:last-child {
        padding-right: 1.25em;
      }
    }
  }

  &__tbody {
    font-size: inherit;
    background-color: $page-bg;
    font-size: 1.35em;

    td {
      padding: 0.463em 0;


      &:first-child {
        padding-left: 0.926em;
      }
      
      &:last-child {
        padding-right: 0.926em;
      }
    }
  }
}