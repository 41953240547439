.analytics {
  display: flex;
  background-color: $page-bg;

  &__logo {
    position: fixed;
    top: 0;
    left: 25.8rem;
    right: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $page-bg;
    padding-top: 2em;
    padding-bottom: 1em;
    padding-right: 25.8rem;
    margin: 0;

    img {
      width: 18.4rem;
    }
  }

  &__section {
    margin-bottom: 3rem;
  }

  &__chart {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    min-height: 20rem;
  }

  &__thead-table {
    width: 100%;
    min-width: 65rem;
  }

  &__analitics-thead {
    font-size: 1.7rem;
  }

  &__analitics-table {
    font-size: 1.7rem;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 50em;
    font-size: 2.4rem;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &__small-section {
    font-size: 0.9em;

    @media screen and (max-width: 600px) {
      font-size: 0.8em;
    }

    &:nth-child(1) {
      margin-right: 4rem;

      @media screen and (max-width: 1000px) {
        margin-right: 0;
        margin-bottom: 4rem;
      }
    }

    &:nth-child(2) {
      width: 100%;

    }
  }
}