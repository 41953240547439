.app-select-search {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 428px;

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    & input {
      box-sizing: border-box;
      height: 48px;
    }
  }

  &__arrow {
    margin-left: 14px;
    cursor: pointer;

    &_open {
      transform: rotate(180deg);
    }
  }

  &__card {
    box-sizing: border-box;
    max-height: 100px;
    overflow-y: scroll;
    border-radius: 16px;
    width: 100%;
    background: #F5F5F5;
    // background: green;

    &-field {
      padding: 3px 20px;
      // box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.156181);
      cursor: pointer;
    }

    &-menu {
      box-sizing: border-box;
      position: absolute;
      z-index: 3;
      // height: 150px;
      padding: 0 20px 12px;
      // box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.156181);
      // top: 0;
      // left: 0;

      &--item {
        font-family: 'Helvetica Neue';
        // font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #5A5A5A;
        margin-top: 8px;
        cursor: pointer;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &_hidden {
      display: none;
    }
  }

  &__search {
    width: 100%;
    height: 48px;

    &-title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }
  }

  &__items {
    height: 150px;
    background: #F5F5F5;
    width: 100%;
    position: absolute;
    z-index: 3;
    // top: 0;
    // left: 0;

    background: green;
  }

  &_open &__card {
    &-field {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.156181);
    }

    &-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.156181);
    }
  }

  &__skeleton {
    width: 100%;
    max-width: 428px;
    height: 54px !important;
  }
}