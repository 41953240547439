.the_localization {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 28px 24px 0 24px;

  width: 88px;
  height: 100%;
}