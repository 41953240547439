.input {
  width: 100%;
  font-size: inherit;
  background-color: $item-bg;
  padding: 0.8em;
  border: none;
  border-radius: 0.708em;
  color: $text-color;
  resize: none;
  overflow: hidden;
  box-shadow: 0 0 0 0.15em transparent;

  &:disabled {
    opacity: 0.6;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  &_big {
    height: 100%;
  }

  &_caps {
    text-transform: uppercase;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.15em $active;
  }
  
  &::placeholder {
    color: $text-color;
    text-transform: uppercase;
    min-height: 100%;
  }
}