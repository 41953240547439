.multiselect {
  position: relative;
  width: max-content;
  width: 20rem;

  &_no-active {
    .multiselect__search {
      border-radius: 1.5rem;
      box-shadow: 0 0 0 0.15em transparent;

      &:hover,
      &:focus {
        box-shadow: 0 0 0 0.15em $active;
      }
    }
  }

  &__search-wrapper {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 1.5rem;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url('../../img/search.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: translateY(-50%);
    }
  }

  &__search {
    width: 100%;
    height: 100%;
    font-size: 1.7rem;
    background-color: $item-bg;
    padding-left: 3rem;
    padding-top: 1.5rem;
    padding: 1.5rem 0 1.5rem 3.5rem;
    border: none;
    border-radius: 1.5rem 1.5rem 0 0;
    box-shadow: 0 0.8em 0.8em 0 rgba(0, 0, 0, 0.12);

    &:hover,
    &:focus {
      box-shadow: 0 0.8em 0.8em 0 rgba(0, 0, 0, 0.12);
    }
  }

  &__list {
    position: absolute;
    top: 90%;
    left: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: $item-bg;
    padding-bottom: 1rem;
    border-radius: 0 0 1.5rem 1.5rem;
    box-shadow: 0 0.8em 0.8em 0 rgba(0, 0, 0, 0.12);
  }

  &__label {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: max-content;
    padding: 0.5rem 0 0.5rem 1.5rem;

    &:hover,
    &:focus {
      background-color: darken($item-bg, 10%);
    }
  }

  &__pseudo-radio {
    font-size: 1.3rem;
    background-color: #fff;
    border-color: #fff;
  }

  &__img {
    max-height: 2.3rem;
    margin-right: 0.5rem;

    img {
      border-radius: 50%;
      width: 100%;
      max-width: 2.3rem;
      height: auto;
    }
  }

  &__text {
    font-size: 1.7rem;
  }
}