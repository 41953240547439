.sticker-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7.3em;
  height: 9.8em;
  font-size: 2.4rem;
  padding: 0.5em 0;
  border-radius: 1em;

  &:hover,
  &:focus {
    font-size: 1em;
    background-color: $page-bg;

    .sticker-card__add {
      display: block;
    }

    .sticker-card__footer {
      display: flex;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 1.05em;
    width: 100%;
    padding: 0 0.4em;
    margin-bottom: 0.5em;
  }

  &__number {
    position: relative;
    bottom: -0.5em;
    font-size: 0.7em;
    font-weight: 700;
  }

  &__emoji {
    display: flex;
    align-items: center;
    
    span {
      font-size: 0.9em;
    }
  }

  &__button {
    width: 1em;
    height: 1em;
    font-size: 1em;
    background-color: transparent;
    border: none;
    object-fit: cover;
    cursor: pointer;
    
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__add {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: $page-bg;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  &__img {
    width: 70%;
  }

  &__footer {
    display: none;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: auto;

    &_drag {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }

}