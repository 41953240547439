.artextfield {
  & label.Mui-focused {
    color: #CF2C37 !important;
    font-size: 16px;
  }

  & label {
    font-size: 14px,
  }

  & .MuiFormHelperText-root {
    font-size: 14px,
  }

  & .MuiOutlinedInput-root {
    border-radius: 23px;
    background: white;
    font-size: 16px;

    & fieldset {
      border-color: white;

      legend {
        font-size: 12px;
      }
    }

    &:hover fieldset {
      border-color: white,
    }

    &.Mui-focused fieldset {
      border-color: #CF2C37;
    }
  }

  &-variant {
    &__filled {
      & label.Mui-focused {
        color: none;
        font-size: 16px;
      }

      & label {
        font-size: 14px;
        display: none;
      }

      & .MuiFormHelperText-root {
        font-size: 14px,
      }

      & .MuiOutlinedInput-root {
        border-radius: 23px;
        background: white;
        font-size: 16px;

        & .MuiInputBase-input {
          box-sizing: border-box;
          height: 54px;
        }

        & fieldset {
          top: 0;
          height: 54px;
          border-color: white;

          legend {
            font-size: 12px;
            display: none;
          }
        }

        &:hover fieldset {
          border-color: white,
        }

        &.Mui-focused fieldset {
          border-color: white;
        }
      }
    }
  }

  &-theme {
    &__default {}

    &__standard {
      & .MuiOutlinedInput-root {
        & .MuiOutlinedInput-input {
          z-index: 1;
          caret-color: #CF2C37;
        }

        & fieldset {
          background: #F5F5F5;
          border-color: #F5F5F5 !important;
        }

        &:hover fieldset {
          border-color: #F5F5F5,
        }

        &.Mui-focused fieldset {
          border-color: #F5F5F5;
        }
      }
    }
  }
}