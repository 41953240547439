.small-section {
  display: flex;
  flex-direction: column;
  min-width: 40rem;
  font-weight: 500;
  background-color: $component-bg;
  padding: 1em 1.458em;
  border-radius: 0.972em;
  box-shadow: 0 0.375em 1.504em rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 600px) {
    min-width: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.667em;
  }

  &__toggle {
    font-size: 0.8em;
  }

  &__chart {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 2em;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__img {
    flex-shrink: 2;
    flex-basis: 45%;
  
      @media screen and (max-width: 600px) {
        width: 70%;
      }

    img {
      width: 100%;
    }
  }

  &__table-wrapper {
    flex-basis: 55%;
    margin-left: 1em;
  }

  &__table {
    @media screen and (max-width: 600px) {
      margin: 1em 0 0;
    }
  }

  &__download-info {
    font-size: 1em;

    td {
      padding-top: 0.2em;
      padding-bottom: 0.2em;
    }
  }
}