.settings {
  display: flex;
  background-color: $page-bg;

  &__section {
    padding: 5rem 0 3rem;
  }

  &__title {
    background-color: $page-bg;
    font-size: 2.7rem;
    padding: 1.7rem 4rem;
  }

  &__form {
    display: flex;
    padding: 0 4rem 2rem;

    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  &__input {
    width: 100%;
    min-width: 15rem;
    height: 4.6rem;
    max-width: 25rem;
    font-size: 1.7rem;
    margin-top: 2rem;
    margin-right: 3rem;

    @media screen and (max-width: 600px) {
      max-width: none;
      margin-right: 0;
    }

    &::placeholder {
      text-transform: none;
    }
  }

  &__submit {
    display: inline-flex;
    align-items: center;
    height: 4.6rem;
    font-size: 1.7rem;
    margin-top: 2rem;
  }
}