.workspace_layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__left_bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__middle_bar {
    flex: 1;
    box-sizing: border-box;
    margin-left: 130px;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &__right_bar {
    min-height: 100vh;
  }

  &__outlet {
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding-left: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
}