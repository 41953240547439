.select {
  position: relative;
  width: 100%;
  max-width: max-content;
  text-transform: initial;

  &_active {
    .select__button {
      // border-radius: 0.9em 0.9em 0 0;
      // box-shadow: 0 0.8em 0.8em 0 rgba(0, 0, 0, 0.12);
      cursor: default;

      &:hover,
      &:focus {
        //background-color: $item-bg;
      }
    }
  }
  &__selected {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5em 1em;
    cursor: pointer;
    user-select: none;

    &-item {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-vector {
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      align-content: center;
      transition: all .2s ease;
      margin-left: 10px;

      &_active {
        transform: rotate(-180deg);
      }
    }
  }

  &__list {
    position: absolute;
    top: 95%;
    flex-direction: column;
    min-width: 100%;
    //background-color: $item-bg;
    //border-radius: 0 0 0.9em 0.9em;
    padding-bottom: 0.5em;
    overflow: scroll;
    // box-shadow: 0 0.8em 0.8em 0 rgba(0, 0, 0, 0.12);
    max-height: 100px;
    background: #FFFF;

    &:not(.select__list_active) {
      display: none;
    }
  }

  &__label {
    display: inline-block;
    cursor: pointer;
    padding: 0.7rem 1em;
    padding-right: 2em;
    width: 100%;
    //white-space: nowrap;

    &:hover,
    &:focus {
      background-color: darken($item-bg, 10%);
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

.select-solid {
  position: relative;
  width: 100%;
  max-width: max-content;
  text-transform: initial;
  background-color: $item-bg;
  border-radius: 0.9em 0.9em 0.9em 0.9em;
  z-index: 1;

  &_active {
    border-radius: 0.9em 0.9em 0 0;
  }
  &__selected {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
    cursor: pointer;
    user-select: none;
    width: 180px;

    &-item {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-vector {
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      align-content: center;
      transition: all .2s ease;
      margin-left: 10px;

      &_active {
        transform: rotate(-180deg);
      }
    }
  }

  &__list {
    position: absolute;
    top: 95%;
    flex-direction: column;
    min-width: 100%;
    background-color: $item-bg;
    border-radius: 0 0 0.9em 0.9em;
    padding-bottom: 0.5em;
    overflow: scroll;
    box-shadow: 0 0.8em 0.8em 0 rgba(0, 0, 0, 0.12);
    max-height: 100px;
    width: 180px;

    &:not(.select-solid__list_active) {
      display: none;
    }
  }

  &__label {
    display: inline-block;
    cursor: pointer;
    padding: 0.7rem 1em;
    padding-right: 2em;
    width: 100%;
    //white-space: nowrap;

    &:hover,
    &:focus {
      background-color: darken($item-bg, 10%);
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}