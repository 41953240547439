.arl-sociallinks {
  position: relative;

  &__block {
    display: none;
    width: 192px;
    padding: 61px 11px 8px 9px;
    position: absolute;
    top: 2px;
    left: -4px;
    background: #D8D8D8;
    border-radius: 23px;
    z-index: -1;

    &_visible {
      display: block;
    }
  }
  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  &__item {
    margin-left: 5px;
    cursor: pointer;
  }
  &__add {
    z-index: 1;
  }
}