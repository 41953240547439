.app-button-add {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    text-transform: none;
  }

  &__logo {
    display: flex;
    margin-left: 10px;
  }
}