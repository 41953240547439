@font-face {
    font-family: 'Gilroy-Medium';
    src: url("../../fonts/Gilroy-Medium.woff") format("woff");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url("../../fonts/HelveticaNeueCyr-Light.woff") format("woff");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url("../../fonts/HelveticaNeueCyr-Medium.woff") format("woff");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url("../../fonts/HelveticaNeueCyr-Bold.woff") format("woff");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "HelveticaNeueCondensed";
    src: url("../../fonts/HelveticaNeueCondensedBold.ttf") format("woff"),
        url("../../fonts/HelveticaNeueCondensedBold.ttf") format("opentype"),
        url("../../fonts/HelveticaNeueCondensedBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}