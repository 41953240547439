.textfield {
  &:disabled {
    background: #EDEDED;
    color: #4D4D4D;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #4D4D4D;
      text-transform: uppercase;
      min-height: 100%;
    }
  }
}