.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 13.8em;
  height: 3.6em;
  font-size: 0.708em;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-color: $active;
  border: none;
  border-radius: 0 0.972em 0 0.5em;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    font-size: 0.55em;
  }

  &__save {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none;
  }

  &__preloader {
    animation: spin infinite linear 1s;
  }

  @keyframes spin {
    100% { 
      transform:rotate(360deg); 
    } 
  }
  
  &:hover,
  &:focus {
    background-color: darken($active, 10%);
  }

  &_green {
    background-color: #73B709;
      
    &:hover,
    &:focus {
      background-color: darken(#73B709, 10%);
    }
  }

  &_orange {
    background-color: #F3B738;

    &:hover,
    &:focus {
      background-color: darken(#F3B738, 10%);
    }
  }
  
  &_bottom-right {
    border-radius: 0.5em 0 0.972em 0;
  }

  &_calendar {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0.588em;
      display: inline-block;
      width: 1.588em;
      height: 1.588em;
      background-image: url('../../img/calendar.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }
}