.info-tab-common {
  &--idpay-commission {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  &--commission {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-left: 24px;

    &-title {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #595959;
    }

    &-input {
      width: 65px !important;
      margin: 0 5px;
    }
  }
}