.auth_layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__back_to_site {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #5C5B5B;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &__middle_bar {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 88px;
    margin-top: 48px;
  }

  &__right_bar {
    min-height: 100vh;
  }

  &__outlet {
    width: 100%;
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    flex: 1;
  }
}